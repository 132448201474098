import api from './../../lib/api';

export const TARIFF_EMAIL_ORGANIZATION_LOAD = 'TARIFF_EMAIL_ORGANIZATION_LOAD';
export const TARIFF_EMAIL_ORGANIZATION_SAVE = 'TARIFF_EMAIL_ORGANIZATION_SAVE';
export const TARIFF_EMAIL_ORGANIZATION_DATATABLE = 'TARIFF_EMAIL_ORGANIZATION_DATATABLE';
export const TARIFF_EMAIL_ORGANIZATION_SET_EMPTY = 'TARIFF_EMAIL_ORGANIZATION_SET_EMPTY';
export const TARIFF_EMAIL_PROVIDER_LOAD_DATATABLE = 'TARIFF_EMAIL_PROVIDER_LOAD_DATATABLE';

const TARIFF_EMAIL_ORGANIZATION_LOADING_CHANGE = 'TARIFF_EMAIL_ORGANIZATION_LOADING_CHANGE';
const TARIFF_EMAIL_ORGANIZATION_CHANGE = 'TARIFF_EMAIL_ORGANIZATION_CHANGE';
const ERRORS_TARIFF_EMAIL_ORGANIZATION_LOAD = 'ERRORS_TARIFF_EMAIL_ORGANIZATION_LOAD';
const LOADING = 'LOADING';
const LOADED_UPDATE = 'LOADED_UPDATE';
const ERRORS_UPDATE = 'ERRORS_UPDATE';
const TARIFF_EMAIL_ORGANIZATION_DATATABLE_LOADING_CHANGE = 'TARIFF_EMAIL_ORGANIZATION_DATATABLE_LOADING_CHANGE';
const TARIFF_EMAIL_ORGANIZATION_DATATABLE_CHANGE = 'TARIFF_EMAIL_ORGANIZATION_DATATABLE_CHANGE';
const SET_EMPTY = 'SET_EMPTY';
const TARIFF_EMAIL_PROVIDER_DATATABLE_LOADING_CHANGE = 'TARIFF_EMAIL_PROVIDER_DATATABLE_LOADING_CHANGE';
const TARIFF_EMAIL_PROVIDER_DATATABLE_CHANGE = 'TARIFF_EMAIL_PROVIDER_DATATABLE_CHANGE';


const tariff_email_empty = () => {
    return {
        info: null,
        tariffs_email: []
    }
}

const state = {
    tariff_emails_organisation: tariff_email_empty(),
    tariff_email_loading: {},
    tariff_email_errors: {},
    tariff_email_list_loading: false,
    tariff_email_list: null,
    tariff_email_list_total: 0,
    tariff_email_provider_list: {}
};

const actions = {
    [TARIFF_EMAIL_ORGANIZATION_LOAD]({commit, state}, id) {
        return api.load(
            'tariff_email.organization',
            id,
            state,
            commit,
            TARIFF_EMAIL_ORGANIZATION_LOADING_CHANGE,
            TARIFF_EMAIL_ORGANIZATION_CHANGE,
            ERRORS_TARIFF_EMAIL_ORGANIZATION_LOAD
        );
    },
    [TARIFF_EMAIL_ORGANIZATION_DATATABLE]({commit, state}, data = {}) {
        return api.list(
            'tariff_email.organization.list',
            0,
            data,
            state,
            commit,
            TARIFF_EMAIL_ORGANIZATION_DATATABLE_LOADING_CHANGE,
            TARIFF_EMAIL_ORGANIZATION_DATATABLE_CHANGE);
    },
    [TARIFF_EMAIL_ORGANIZATION_SAVE]({commit, state}, data) {
        if (data.type !== "new") {
            return api.update('tariff_email.organization.update', data.id, data, state, commit, LOADING, LOADED_UPDATE, ERRORS_UPDATE);
        } else {
            return api.create('tariff_email.organization.create', data.id, data, state, commit, LOADING, LOADED_UPDATE, ERRORS_UPDATE);
        }
    },
    [TARIFF_EMAIL_PROVIDER_LOAD_DATATABLE]({commit, state}, id) {
        id = !id ? id : 0;
        return api.list(
            'tariff_email.providers.list',
            id,
            null,
            state,
            commit,
            TARIFF_EMAIL_PROVIDER_DATATABLE_LOADING_CHANGE,
            TARIFF_EMAIL_PROVIDER_DATATABLE_CHANGE);
    },
    [TARIFF_EMAIL_ORGANIZATION_SET_EMPTY]({commit}) {
        commit(SET_EMPTY);
    },
};

const mutations = {
    [TARIFF_EMAIL_ORGANIZATION_LOADING_CHANGE](state, data) {
        state.tariff_email_loading = data;
    },
    [TARIFF_EMAIL_ORGANIZATION_CHANGE](state, data) {
        if (data) {
            state.tariff_emails_organisation = data;
        }
    },
    [ERRORS_TARIFF_EMAIL_ORGANIZATION_LOAD](state, data) {
        state.tariff_email_errors = data;
    },
    [LOADING](state, data) {
        state.tariff_email_loading = data;
    },
    [LOADED_UPDATE](state, data) {
        if (data) {
            state.tariff_email_organization = data;
        }
    },
    [ERRORS_UPDATE](state, data) {
        state.tariff_email_errors = data;
    },
    [TARIFF_EMAIL_ORGANIZATION_DATATABLE_LOADING_CHANGE](state, data) {
        state.tariff_email_list_loading = data;
    },
    [TARIFF_EMAIL_ORGANIZATION_DATATABLE_CHANGE](state, data) {
        if (data) {
            state.tariff_email_list = data.data;
            state.tariff_email_list_total = data.total;
        } else {
            state.tariff_email_list = [];
            state.tariff_email_list_total = 0;
        }
    },
    [TARIFF_EMAIL_PROVIDER_DATATABLE_LOADING_CHANGE](state, data) {
        state.tariff_email_provider_list_loading = data;
    },
    [TARIFF_EMAIL_PROVIDER_DATATABLE_CHANGE](state, data) {
        if (data) {
            state.tariff_email_provider_list = data.data;
        } else {
            state.tariff_email_provider_list = [];
        }
    },
    [SET_EMPTY](state) {
        state.tariff_emails_organisation = tariff_email_empty();
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
