import api from '../../../lib/api';

export const EMAIL_TEMPLATES_LIST_LOAD = "EMAIL_TEMPLATES_LIST_LOAD";
export const SELECT_EMAIL_TEMPLATES_LIST_LOAD = "SELECT_EMAIL_TEMPLATES_LIST_LOAD";
export const SELECT_EMAIL_TEMPLATES_LIST_SEARCH = "SELECT_EMAIL_TEMPLATES_LIST_SEARCH";
export const EMAIL_TEMPLATE_LOAD = "EMAIL_TEMPLATE_LOAD";
export const EMAIL_TEMPLATE_CREATE = "EMAIL_TEMPLATE_CREATE";
export const EMAIL_TEMPLATE_UPDATE = "EMAIL_TEMPLATE_UPDATE";
export const EMAIL_TEMPLATES_ORDER_LOAD = "EMAIL_TEMPLATES_ORDER_LOAD";

const EMAIL_TEMPLATES_LIST_CHANGE = "EMAIL_TEMPLATES_LIST_CHANGE";
const EMAIL_TEMPLATES_LIST_LOADING_CHANGE = "EMAIL_TEMPLATES_LIST_LOADING_CHANGE";

const SELECT_EMAIL_TEMPLATES_LIST_CHANGE = "SELECT_EMAIL_TEMPLATES_LIST_CHANGE";
const SELECT_EMAIL_TEMPLATES_LIST_LOADING_CHANGE = "SELECT_EMAIL_TEMPLATES_LIST_LOADING_CHANGE";

const EMAIL_TEMPLATE_CHANGE = "EMAIL_TEMPLATE_CHANGE";
const EMAIL_TEMPLATE_LOADING_CHANGE = "EMAIL_TEMPLATE_LOADING_CHANGE";
const EMAIL_TEMPLATE_ERRORS_CHANGE = "EMAIL_TEMPLATE_ERRORS_CHANGE";
const EMAIL_TEMPLATE_SAVING_CHANGE = "EMAIL_TEMPLATE_SAVING_CHANGE";

const EMAIL_TEMPLATE_CREATING_CHANGE = "EMAIL_TEMPLATE_CREATING_CHANGE";
const EMAIL_TEMPLATE_CREATING_ERRORS_CHANGE = "EMAIL_TEMPLATE_CREATING_ERRORS_CHANGE";

const email_template_empty = {
    id: 0,
    name: "",
    subject: "",
    content: "",
    is_work: false,
    organization_id: [],
    options: {
        is_shorten_link: false,
        default_redirect_url: '',
        redirect_delay: 1,
        days_until_expiration: 90,
        domains: [],
        signal: null,
    },
};

const state = {
    email_template: email_template_empty,
    email_template_loading: false,
    email_template_saving: false,
    email_template_errors: {},

    email_template_list: null,
    email_template_list_loading: false,

    email_templates_list: null,
    select_email_template_list_loading: false,

    email_template_creating: false,
    email_template_creating_errors: {},
};

const actions = {
    [EMAIL_TEMPLATES_LIST_LOAD]({commit, state}, data) {
        let id = data && data.id ? data.id : 0;
        let params = data && data.params ? data.params : null;

        return api.list(
            'email_templates',
            id,
            params,
            state,
            commit,
            EMAIL_TEMPLATES_LIST_LOADING_CHANGE,
            EMAIL_TEMPLATES_LIST_CHANGE,
        );
    },

    [SELECT_EMAIL_TEMPLATES_LIST_LOAD]({commit, state}, data) {
        let id = data && data.id ? data.id : 0;
        let params = data && data.params ? data.params : null;

        return api.list(
            'email_templates.simple_list',
            id,
            params,
            state,
            commit,
            SELECT_EMAIL_TEMPLATES_LIST_LOADING_CHANGE,
            SELECT_EMAIL_TEMPLATES_LIST_CHANGE,
        );
    },

    [SELECT_EMAIL_TEMPLATES_LIST_SEARCH]({commit, state}, data) {
        let id = data && data.id ? data.id : 0;
        let params = data ? data : null;

        return api.list(
            'email_templates.search',
            id,
            params,
            state,
            commit,
            SELECT_EMAIL_TEMPLATES_LIST_LOADING_CHANGE,
            SELECT_EMAIL_TEMPLATES_LIST_CHANGE,
        );
    },

    [EMAIL_TEMPLATES_ORDER_LOAD]({commit, state}, id) {
        return api.load(
            'email_templates_by_order',
            id,
            state,
            commit,
            EMAIL_TEMPLATES_LIST_LOADING_CHANGE,
            EMAIL_TEMPLATES_LIST_CHANGE,
        );
    },

    [EMAIL_TEMPLATE_LOAD]({commit, state}, id = 0) {
        if (!id) {
            commit(EMAIL_TEMPLATE_CHANGE, null);
            return
        }

        return api.load(
            'email_template',
            id,
            state,
            commit,
            EMAIL_TEMPLATE_LOADING_CHANGE,
            EMAIL_TEMPLATE_CHANGE,
        );
    },
    [EMAIL_TEMPLATE_CREATE]({state, commit}, data) {
        let id = data.id ? data.id : 0;
        let model = data.model;

        if (_.has(model.options, 'cut_https') && !model.options.cut_https) {
            delete model.options.cut_https;
        }

        if (model.options.is_shorten_link === false) {
            model.options = _.pick(model.options, ['is_shorten_link', 'cut_https']);
        }

        return api.create(
            'email_templates',
            id,
            model,
            state,
            commit,
            EMAIL_TEMPLATE_CREATING_CHANGE,
            EMAIL_TEMPLATE_CHANGE,
            EMAIL_TEMPLATE_CREATING_ERRORS_CHANGE,
        );
    },
    [EMAIL_TEMPLATE_UPDATE]({state, commit}, data) {
        let id = data.id;
        let model = data.model;

        if (_.has(model.options, 'cut_https') && !model.options.cut_https) {
            delete model.options.cut_https;
        }

        if (model.options.is_shorten_link === false) {
            model.options = _.pick(model.options, ['is_shorten_link', 'cut_https']);
        }

        return api.update(
            'email_template',
            id,
            model,
            state,
            commit,
            EMAIL_TEMPLATE_SAVING_CHANGE,
            EMAIL_TEMPLATE_CHANGE,
            EMAIL_TEMPLATE_ERRORS_CHANGE,
        );
    },
};

const mutations = {
    [EMAIL_TEMPLATES_LIST_CHANGE](state, data) {
        if (data) {
            state.email_template_list = data.data ? data.data : data;
        }
    },
    [EMAIL_TEMPLATES_LIST_LOADING_CHANGE](state, data) {
        state.email_template_list_loading = data;
    },
    [SELECT_EMAIL_TEMPLATES_LIST_CHANGE](state, data) {
        if (data) {
            state.email_templates_list = data.data ? data.data : data;
        }
    },
    [SELECT_EMAIL_TEMPLATES_LIST_LOADING_CHANGE](state, data) {
        state.select_email_template_list_loading = data;
    },
    [EMAIL_TEMPLATE_CHANGE](state, data) {
        state.email_template = _.cloneDeep(email_template_empty);

        if (data) {
            state.email_template = _.merge({}, state.email_template, data)
        }
    },
    [EMAIL_TEMPLATE_LOADING_CHANGE](state, data) {
        state.email_template_loading = data;
    },
    [EMAIL_TEMPLATE_ERRORS_CHANGE](state, data) {
        state.email_template_errors = data;
    },
    [EMAIL_TEMPLATE_SAVING_CHANGE](state, data) {
        state.email_template_saving = data;
    },
    [EMAIL_TEMPLATE_CREATING_CHANGE](state, data) {
        state.email_template_creating = data;
    },
    [EMAIL_TEMPLATE_CREATING_ERRORS_CHANGE](state, data) {
        state.email_template_creating_errors = data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
}