import api from './../../lib/api';
import Vue from 'vue';
import _ from 'lodash';
import VueRouter from "./../../routes.js";
import actionsParams from '@/lib/bpm/actions';
import {
    validateAction,
} from '@/lib/bpm';

export const BPM_DB_LOAD_DATATABLE       = 'BPM_DB_LOAD_DATATABLE';
export const BPM_DB_LOAD       = 'BPM_DB_LOAD';
export const BPM_DB_SAVE       = 'BPM_DB_SAVE';
export const BPM_CONTRAHEN_LOAD = 'BPM_CONTRAHEN_LOAD';
export const BPM_CONTRAHEN_SAVE = 'BPM_CONTRAHEN_SAVE';
export const BPM_BP_LOAD_DATATABLE = 'BPM_BP_LOAD_DATATABLE';
export const BPM_BP_LOAD_FOR_SOLUTION  = 'BPM_BP_LOAD_FOR_SOLUTION';
export const BPM_BP_LOAD_DATATABLE_ALL = 'BPM_BP_LOAD_DATATABLE_ALL';
export const BPM_BP_LOAD = 'BPM_BP_LOAD';
export const BPM_BP_EXPORT = 'BPM_BP_EXPORT';
export const BPM_BP_IMPORT = 'BPM_BP_IMPORT';
export const BPM_BP_LOAD_ERRORS = 'BPM_BP_LOAD_ERRORS';
export const BPM_BP_COPY = 'BPM_BP_COPY';
export const BPM_SET_EMPTY = 'BPM_SET_EMPTY';
export const BPM_BP_SAVE = 'BPM_BP_SAVE';
export const BPM_BP_UPDATE_BPS = 'BPM_BP_UPDATE_BPS';
export const BPM_BP_API_SAVE = 'BPM_BP_API_SAVE';
export const BPM_BP_PLANNER_LOAD = 'BPM_BP_PLANNER_LOAD';
export const BPM_BP_PLANNER_SAVE = 'BPM_BP_PLANNER_SAVE';
export const BPM_BP_SMS_PROVIDERS = 'BPM_BP_SMS_PROVIDERS';
export const BPM_BP_EMAIL_PROVIDERS = 'BPM_BP_EMAIL_PROVIDERS';
export const BPM_RESTORE_ACTION = 'BPM_RESTORE_ACTION';
export const BPM_RESTORE_ACTION_JUMP = 'BPM_RESTORE_ACTION_JUMP';
export const BPM_ADD_ACTION_JUMP = 'BPM_ADD_ACTION_JUMP';
export const BPM_LOAD_STATS = 'BPM_LOAD_STATS';
export const BPM_BP_CHECK = 'BPM_BP_CHECK';
export const BPM_RUN_STUCK = 'BPM_RUN_STUCK';
export const BPM_RUN_ERROR_SMS = 'BPM_RUN_ERROR_SMS';

const BPM_DB_DATATABLE_LOADING_CHANGE = 'BPM_DB_DATATABLE_LOADING_CHANGE';
const BPM_DB_DATATABLE_CHANGE = 'BPM_DB_DATATABLE_CHANGE';
const BPM_DB_LOADING_CHANGE = 'BPM_DB_LOADING_CHANGE';
const BPM_DB_CHANGE = 'BPM_DB_CHANGE';
const LOADING = 'LOADING';
const LOADED_CREATE = 'LOADED_CREATE';
const LOADED_UPDATE = 'LOADED_UPDATE';
const ERRORS = 'ERRORS';
const ERRORS_DB_LOAD = 'ERRORS_DB_LOAD';
const BPM_SET_EMPTY_DB = 'BPM_SET_EMPTY_DB';
const LOADING_BP_PLANNER = 'LOADING_BP_PLANNER';
const LOADED_BP_PLANNER_UPDATE = 'LOADED_BP_PLANNER_UPDATE';
const ERRORS_BP_PLANNER_UPDATE = 'ERRORS_BP_PLANNER_UPDATE';

const BPM_CONTRAHEN_CHANGE = 'BPM_CONTRAHEN_CHANGE';
const BPM_CONTRAHEN_LOADING_CHANGE = 'BPM_CONTRAHEN_LOADING_CHANGE';
const ERRORS_CONTRAHEN_LOAD = 'ERRORS_CONTRAHEN_LOAD';
const LOADED_CONTRAHEN_UPDATE = 'LOADED_CONTRAHEN_UPDATE';
const LOADED_CONTRAHEN_CREATE = 'LOADED_CONTRAHEN_CREATE';
const BPM_SET_EMPTY_CONTRAHEN = 'BPM_SET_EMPTY_CONTRAHEN';

const BPM_BP_DATATABLE_LOADING_CHANGE = 'BPM_BP_DATATABLE_LOADING_CHANGE';
const BPM_BP_DATATABLE_CHANGE = 'BPM_BP_DATATABLE_CHANGE';
const BPM_BP_LOADING_CHANGE = 'BPM_BP_LOADING_CHANGE';
const BPM_BP_CHANGE = 'BPM_BP_CHANGE';
const BPM_BP_EXPORTED = 'BPM_BP_EXPORTED';
const BPM_BP_IMPORTED = 'BPM_BP_IMPORTED';
const BPM_BP_ERRORS_LOADING_CHANGE = 'BPM_BP_ERRORS_LOADING_CHANGE';
const BPM_BP_ERRORS_CHANGE = 'BPM_BP_ERRORS_CHANGE';
const BPM_BP_COPIED = 'BPM_BP_COPIED';
const BPM_BP_SMS_PROVIDERS_LOADING_CHANGE = 'BPM_BP_SMS_PROVIDERS_LOADING_CHANGE';
const BPM_BP_SMS_PROVIDERS_CHANGE = 'BPM_BP_SMS_PROVIDERS_CHANGE';
const BPM_BP_EMAIL_PROVIDERS_LOADING_CHANGE = 'BPM_BP_EMAIL_PROVIDERS_LOADING_CHANGE';
const BPM_BP_EMAIL_PROVIDERS_CHANGE = 'BPM_BP_EMAIL_PROVIDERS_CHANGE';
const ERRORS_BP_LOAD = 'ERRORS_BP_LOAD';
const LOADED_BP_UPDATE = 'LOADED_BP_UPDATE';
const LOADED_BP_CREATE = 'LOADED_BP_CREATE';
const BPM_SET_EMPTY_BP = 'BPM_SET_EMPTY_BP';
const ERRORS_BP_UPDATE = 'ERRORS_BP_UPDATE';
const BPM_BP_CHECK_LOADING = 'BPM_BP_CHECK_LOADING';
const BPM_BP_CHECK_CHANGE = 'BPM_BP_CHECK_CHANGE';
const BPM_BP_CHECK_ERRORS = 'BPM_BP_CHECK_ERRORS';
const ACTION_RESTORED = 'ACTION_RESTORED';
const ACTION_JUMP_RESTORED = 'ACTION_JUMP_RESTORED';
const BPM_STATS_LOADING = 'BPM_STATS_LOADING';
const BPM_STATS_LOADED = 'BPM_STATS_LOADED';
const BPM_STUCK_LOADING = 'BPM_STUCK_LOADING';
const BPM_STUCK_LOADED = 'BPM_STUCK_LOADED';
const BPM_ERROR_SMS_LOADING = 'BPM_ERROR_SMS_LOADING';
const BPM_ERROR_SMS_LOADED = 'BPM_ERROR_SMS_LOADED';

export const BPM_SAVE_JUMP_ARROW_POS = 'BPM_SAVE_JUMP_ARROW_POS';
export const BPM_STATS_SET_OPTIONS = 'BPM_STATS_SET_OPTIONS';

const bp_empty = () => {
    return {
        title: "",
        description: "",
        actions: [
            {
                tid: 'a0000000000000000000000000000001',
                type: 'start',
                title: 'Start',
                constraints: {
                    left: 20,
                    top: 20,
                    width: 40,
                    height: 40,
                },
                jumps: [],
            }
        ],
        jumps: {},
        channels: 0,
        init_jump: {
            linked_action_id: null,
            bpm_jump_id: null,
        },
        use_one_phone_number: false,
        priority: 0,
        config:"",
        status: false,
    }
}

const db_empty = () => {
    return {
        title: "",
        status: true,
        api_key: ""
    }
}

const contrahen_empty = () => {
    return {
        full_name: "",
        phone: "",
        sex: 0,
        race: 0,
        age: null,
        city_id: null,
        params: {},
        hrm_params: {},
    }
}

const defaults = {
    cols: 7,
    width: 160,
    height: 80,
    gap: 20,
    gateway: {
        width: 40,
        height: 40,
        gap: 20,
    },
};

const state = {
    db_list_total     : 0,
    db_list           : null,
    db_list_loading   : false,
    db                : db_empty(),
    db_loading        : {},
    db_errors:{},

    contrahen         : contrahen_empty(),
    // contrahen         : contrahen_empty,
    contrahen_loading        : {},
    contrahen_errors: {},

    bp_list_total     : 0,
    bp_list           : null,
    bp_list_loading   : false,
    bp                : bp_empty(),
    bp_exported       : null,
    bp_loading        : {},
    bp_errors         : {},
    bp_sms_providers_loading: [],
    bp_sms_providers  : [],
    bp_email_providers_loading: [],
    bp_email_providers  : [],
    bp_planners:{},
    bp_planners_loading:{},
    bp_planners_errors:{},
    stats: {},
    stats_loading: false,
    stuck: {},
    stuck_loading: false,
    error_sms: {},
    error_sms_loading: false,
    jumpPosList: {},
    statsOptions: {
        jumps: true,
        conversion: true,
        cost: false,
        from: null,
        to: null,
    },

    bp_check_loading:{},
    bp_check:{},
    bp_check_errors:{},
    loading_errors_log: false,
    errors_log_total: 0,
    errors_log: [],
};

const actions = {
    // BP - Бизнес-процессы
    [BPM_BP_LOAD_DATATABLE] ({ commit, state }, data) {
        return api.list(
            'bpm.bp.list',
            0,
            data,
            state,
            commit,
            BPM_BP_DATATABLE_LOADING_CHANGE,
            BPM_BP_DATATABLE_CHANGE);
    },
    [BPM_BP_LOAD_DATATABLE_ALL] ({ commit, state }, id) {
        return api.list(
            'bpm.bp.list.all',
            id,
            {},
            state,
            commit,
            BPM_BP_DATATABLE_LOADING_CHANGE,
            BPM_BP_DATATABLE_CHANGE);
    },
    [BPM_BP_EXPORT] ({commit, state}, { id, params }) {
        return api.list(
            'bpm.bp.export',
            id,
            params,
            state,
            commit,
            BPM_BP_LOADING_CHANGE,
            BPM_BP_EXPORTED
        );
    },
    [BPM_BP_IMPORT]({ commit, state }, { file }) {
        const data = new FormData();
        data.append('bpm', file);
        data.append('is_file', true);
        return api.create(
            'bpm.bp.import',
            0,
            data,
            state,
            commit,
            BPM_BP_LOADING_CHANGE,
            BPM_BP_IMPORTED,
            ERRORS_BP_UPDATE
        );
    },
    // получение параметров БП по его ключу
    [BPM_BP_LOAD] ({commit, state}, id) {
        return api.load(
            'bpm.bp',
            id,
            state,
            commit,
            BPM_BP_LOADING_CHANGE,
            BPM_BP_CHANGE,
            ERRORS_BP_LOAD
        );
    },
    // получение параметров БП по ID шаблона решения
    [BPM_BP_LOAD_FOR_SOLUTION] ({commit, state}, id) {
        return api.load(
            'bpm.bp.for_solution',
            id,
            state,
            commit,
            BPM_BP_LOADING_CHANGE,
            BPM_BP_CHANGE,
            ERRORS_BP_LOAD
        );
    },
    [BPM_BP_SAVE]({ commit, state }, data) {
        if (data.key != "new") {
            return api.update('bpm.bp.update', data.key, data.model, state, commit, LOADING, BPM_BP_CHANGE, ERRORS_BP_UPDATE);
        } else {
            return api.create('bpm.bp.create', 0, data.model, state, commit, LOADING, LOADED_BP_CREATE, ERRORS_BP_UPDATE);
        }
    },
    [BPM_BP_UPDATE_BPS]({ commit, state }, data) {
        return api.create(
            'bpm.bp.update_bps',
            0,
            data,
            state,
            commit,
            BPM_BP_DATATABLE_LOADING_CHANGE,
            BPM_BP_DATATABLE_CHANGE,
            ERRORS_BP_UPDATE
        );
    },
    [BPM_BP_API_SAVE]({ commit, state }, data) {
        return api.create('bpm.bp.api.update', data.key, data.model, state, commit, LOADING, LOADED_BP_UPDATE, ERRORS_BP_UPDATE);
    },
    [BPM_BP_PLANNER_LOAD] ({ commit, state }, id) {
        return api.list(
            'bpm.bp.planner.get',
            id,
            {},
            state,
            commit,
            LOADING_BP_PLANNER,
            LOADED_BP_PLANNER_UPDATE);
    },
    [BPM_BP_PLANNER_SAVE]({ commit, state }, data) {
        return api.create('bpm.bp.planner.update', data.key, data.model, state, commit, LOADING_BP_PLANNER, LOADED_BP_PLANNER_UPDATE, ERRORS_BP_PLANNER_UPDATE);
    },

    [BPM_BP_CHECK]({ commit, state }, id) {
        return api.load('bpm.bp.check', id, state, commit, BPM_BP_CHECK_LOADING, BPM_BP_CHECK_CHANGE, BPM_BP_CHECK_ERRORS);
    },
    [BPM_BP_LOAD_ERRORS]({ commit, state }, data) {
        return api.list('bpm.bp.errors', 0, data, state, commit, BPM_BP_ERRORS_LOADING_CHANGE, BPM_BP_ERRORS_CHANGE);
    },
    [BPM_BP_COPY] ({commit, state}, data) {
        return api.create('bpm.bp.copy', data.key, data, state, commit, BPM_BP_LOADING_CHANGE, BPM_BP_COPIED, ERRORS_BP_UPDATE);
    },
    [BPM_BP_SMS_PROVIDERS] ({ commit, state }, id) {
        return api.list(
            'bpm.bp.sms_providers',
            id,
            {},
            state,
            commit,
            BPM_BP_SMS_PROVIDERS_LOADING_CHANGE,
            BPM_BP_SMS_PROVIDERS_CHANGE);
    },
    [BPM_BP_EMAIL_PROVIDERS] ({ commit, state }, id) {
        return api.list(
            'bpm.bp.email_providers',
            id,
            {},
            state,
            commit,
            BPM_BP_EMAIL_PROVIDERS_LOADING_CHANGE,
            BPM_BP_EMAIL_PROVIDERS_CHANGE);
    },

    // DB - Базы контрагентов
    [BPM_DB_LOAD_DATATABLE] ({ commit, state }, data) {
        return api.list(
            'bpm.db.list',
            0,
            data,
            state,
            commit,
            BPM_DB_DATATABLE_LOADING_CHANGE,
            BPM_DB_DATATABLE_CHANGE);
    },
    [BPM_DB_LOAD] ({commit, state}, id) {
        return api.load(
            'bpm.db',
            id,
            state,
            commit,
            BPM_DB_LOADING_CHANGE,
            BPM_DB_CHANGE,
            ERRORS_DB_LOAD
        );
    },
    [BPM_DB_SAVE]({ commit, state }, data) {
        if (data.key != "new") {
            return api.create('bpm.db.update', data.key, data.model, state, commit, LOADING, LOADED_UPDATE, ERRORS);
        } else {
            return api.create('bpm.db.create', 0, data.model, state, commit, LOADING, LOADED_CREATE, ERRORS);
        }
    },


    // CONTRAHEN - Контрагенты
    [BPM_CONTRAHEN_LOAD] ({commit, state}, id) {
        return api.load(
            'bpm.contrahen',
            id,
            state,
            commit,
            BPM_CONTRAHEN_LOADING_CHANGE,
            BPM_CONTRAHEN_CHANGE,
            ERRORS_CONTRAHEN_LOAD
        );
    },
    [BPM_CONTRAHEN_SAVE]({ commit, state }, data) {
        if (data.key != "new") {
            return api.create('bpm.contrahen.update', data.key, data.model, state, commit, LOADING, LOADED_CONTRAHEN_UPDATE, ERRORS_CONTRAHEN_LOAD);
        } else {
            return api.create('bpm.contrahen.create', 0, data.model, state, commit, LOADING, LOADED_CONTRAHEN_CREATE, ERRORS_CONTRAHEN_LOAD);
        }
    },

    // Сброс значений
    [BPM_SET_EMPTY]({ commit }, data) {
        if (data === "contrahen") {
            commit(BPM_SET_EMPTY_CONTRAHEN);
        } else if(data === "db") {
            commit(BPM_SET_EMPTY_DB);
        } else if(data === "bp") {
            commit(BPM_SET_EMPTY_BP);
        }
    },

    // Восстановление действия по id
    [BPM_RESTORE_ACTION]({ commit, state }, id) {
        return api.load('bpm.actions.restore', id, state, commit, LOADING, ACTION_RESTORED, ERRORS);
    },

    // Восстановление перехода действия по id
    [BPM_RESTORE_ACTION_JUMP]({ commit, state }, id) {
        return api.load('bpm.actions.restore_jump', id, state, commit, LOADING, ACTION_JUMP_RESTORED, ERRORS);
    },
    // Добавление связи перехода с действием
    [BPM_ADD_ACTION_JUMP]({ commit, state }, data) {
        return api.create('bpm.actions.add_jump', data.id, data.model, state, commit, LOADING, ACTION_JUMP_RESTORED, ERRORS);
    },

    // Загрузка статистики БП за период
    [BPM_LOAD_STATS]({ commit, state }, data) {
        return api.list(
            'bpm.bp.get_stats',
            data.key,
            data,
            state,
            commit,
            LOADING,
            BPM_STATS_LOADED);
    },

    // Отправляет зависшие заказы на поиск перехода
    [BPM_RUN_STUCK]({ commit, state }, data) {
        return api.list(
            'bpm.bp.run_stuck',
            data.action_id,
            data,
            state,
            commit,
            BPM_STUCK_LOADING,
            BPM_STUCK_LOADED);
    },

    // Отправляет ошибочные смс на переотправку
    [BPM_RUN_ERROR_SMS]({ commit, state }, data) {
        return api.list(
            'bpm.bp.run_error_sms',
            data.action_id,
            data,
            state,
            commit,
            BPM_ERROR_SMS_LOADING,
            BPM_ERROR_SMS_LOADED);
    },
};

const mutations = {
    // BP - Бизнес-процессы
    [BPM_BP_DATATABLE_LOADING_CHANGE](state, data) {
        state.bp_list_loading = data;
    },
    [BPM_BP_DATATABLE_CHANGE](state, data) {
        if(data) {
            state.bp_list        = data.data;
            state.bp_list_total  = data.total;
        }
        else {
            state.bp_list        = [];
            state.bp_list_total  = 0;
        }
    },
    [BPM_BP_LOADING_CHANGE] (state, data) {
        state.bp_loading = data;
    },
    [BPM_BP_CHANGE] (state, data) {
        if (!data) {
            return;
        }
        // ищем действие Корзина в БП
        let isSegmentTrash = data.actions.find(item => {
            return item.type == 'segment_trash';
        });
        let hasSegmentTrash = null;
        if (isSegmentTrash) {
            hasSegmentTrash = true;
        }

        data.actions.forEach((action, index) => {
            const params = actionsParams[action.type] ? actionsParams[action.type] : {
                width: defaults.width,
                height: defaults.height,
            };
            const left = index % defaults.cols * (defaults.width + defaults.gateway.gap + defaults.gateway.width + defaults.gap) + defaults.gap;
            const top = Math.floor(index / defaults.cols) * (defaults.height + defaults.gap) + defaults.gap;
            if (!action.constraints) {
                action.constraints = {
                    left,
                    top,
                    width: params.width ? params.width : defaults.width,
                    height: params.height ? params.height : defaults.height,
                };
            }
            if (!action.constraints.gateway) {
                action.constraints.gateway = {
                    left: left + defaults.width + defaults.gateway.gap,
                    top: top + ((defaults.height - defaults.gateway.height) / 2),
                    width: defaults.gateway.width,
                    height: defaults.gateway.height,
                };
            }
            action.errors = validateAction(action, JSON.parse(action.options), hasSegmentTrash).error;
        });

        // Проверяем яркость цвета стелок и устанавливаем цвет текста белый/чёрный
        if (_.isObject(data.jumps)) {
            for (let jump_id in data.jumps) {
                if (data.jumps.hasOwnProperty(jump_id)) {
                    const jump = data.jumps[jump_id];
                    if (_.isString(jump.color)) {
                        if (jump.color.length === 4) {
                            Vue.set(jump, 'color', jump.color + jump.color.substring(1, 4));
                        }
                        const r = parseInt(jump.color.substring(1, 3), 16);
                        const g = parseInt(jump.color.substring(3, 5), 16);
                        const b = parseInt(jump.color.substring(5, 7), 16);
                        const bright = 0.21 * r + 0.72 * g + 0.07 * b;
                        if (bright > 160) {
                            Vue.set(jump, 'textColor', '#23282c');
                        } else {
                            Vue.set(jump, 'textColor', '#ffffff');
                        }
                        Vue.set(jump, 'bright', bright);
                    } else {
                        Vue.set(jump, 'textColor', '#23282c');
                        Vue.set(jump, 'color', '#cccccc');
                        Vue.set(jump, 'bright', 204.0);
                    }
                }
            }
        }
        state.bp = data;
    },
    [BPM_BP_EXPORTED] (state, data) {
        if (data && data.data) {
            state.bp_exported = data.data;
            window.open(data.data, 'Save me...', 'popup');
        } else {
            state.bp_exported = null;
        }
    },
    [BPM_BP_IMPORTED] (state, data) {
        if (state) {
            VueRouter.push({
                name: 'bpm-bp',
                params: { id: data.key },
            });
        }
    },
    [LOADED_BP_UPDATE](state, data) {
        if (data) {
            state.bp = data;
        }
    },
    [LOADED_BP_CREATE](state, data) {
        // Если сохранено - редирект на список
        if (data) {
            VueRouter.push("/bpm/bp_list/");
        }
    },
    [ERRORS_BP_LOAD](state, data) {
        if (data.key) {
            // Если ошибка по key - редирект на страницу ошибки
            VueRouter.push("/404");
        }else{
            state.bp_errors = data;
        }
    },
    [ERRORS_BP_UPDATE](state, data) {
        state.bp_errors = data;
    },
    [BPM_SET_EMPTY_BP](state) {
        state.bp = bp_empty();
        state.stats = {};
        state.jumpPosList = {};
    },
    [BPM_BP_CHECK_LOADING] (state, data) {
        state.bp_check_loading = data;
    },
    [BPM_BP_CHECK_CHANGE] (state, data) {
        if(data){
            state.bp_check = data.data;
        }
    },
    [BPM_BP_CHECK_ERRORS](state, data) {
        if (data) {
            state.bp_check_errors = data;
        }
    },

    [BPM_BP_ERRORS_LOADING_CHANGE](state, data) {
        state.loading_errors_log = data;
    },
    [BPM_BP_ERRORS_CHANGE](state, response) {
        if (response && response.data) {
            state.errors_log = response.data;
            state.errors_log_total = response.total;
        } else {
            state.errors_log_total = 0;
            state.errors_log = [];
        }
    },
    [BPM_BP_SMS_PROVIDERS_LOADING_CHANGE](state, data) {
        state.bp_sms_providers_loading = data;
    },
    [BPM_BP_SMS_PROVIDERS_CHANGE](state, data) {
        if(data) {
            state.bp_sms_providers = data.data;
        }
    },
    [BPM_BP_EMAIL_PROVIDERS_LOADING_CHANGE](state, data) {
        state.bp_email_providers_loading = data;
    },
    [BPM_BP_EMAIL_PROVIDERS_CHANGE](state, data) {
        if(data) {
            state.bp_email_providers = data.data;
        }
    },
    [LOADING_BP_PLANNER](state, data) {
        state.bp_planners_loading        = data;
    },
    [LOADED_BP_PLANNER_UPDATE](state, data) {
        state.bp_planners = {};
        if (data && data.data) {
            state.bp_planners = data.data;
        }
    },
    [ERRORS_BP_PLANNER_UPDATE](state, data) {
        state.bp_planners_errors = data;
    },

    // DB - Базы контрагентов
    [BPM_DB_DATATABLE_LOADING_CHANGE](state, data) {
        state.db_list_loading = data;
    },
    [BPM_DB_DATATABLE_CHANGE](state, data) {
        if(data) {
            state.db_list        = data.data;
            state.db_list_total  = data.total;
        }
        else {
            state.db_list        = [];
            state.db_list_total  = 0;
        }
    },
    [BPM_DB_LOADING_CHANGE] (state, data) {
        state.db_loading = data;
    },
    [BPM_DB_CHANGE] (state, data) {
        if(data){
            state.db = data;
        }
    },
    [BPM_BP_COPIED] (state, data) {
        if (state) {
          VueRouter.push({
            name: 'bpm-bp',
            params: { id: data.key },
          });
        }
    },
    [LOADED_UPDATE](state, data) {
        if (data) {
            state.db = data;
        }
    },
    [ERRORS_DB_LOAD](state, data) {
        if (data.key) {
            // Если ошибка по key - редирект на страницу ошибки
            VueRouter.push("/404");
        }else{
            state.db_errors = data;
        }

    },
    [LOADED_CREATE](state, data) {
        // Если сохранено - редирект на список
        if (data) {
            VueRouter.push("/bpm/db_list/");
        }
    },
    [ERRORS](state, data) {
        state.db_errors = data;
    },
    [BPM_SET_EMPTY_DB](state) {
        state.db = db_empty();
    },


    // CONTRAHEN - Контрагенты
    [BPM_CONTRAHEN_LOADING_CHANGE] (state, data) {
        state.contrahen_loading = data;
    },
    [BPM_CONTRAHEN_CHANGE] (state, data) {
        if(data){
            state.contrahen = data;
        }
    },
    [LOADED_CONTRAHEN_CREATE](state, data) {
        // Если сохранено - редирект на созданного контрагента
        if (data) {
            VueRouter.push("/bpm/contrahen_list/");
        }
    },
    [LOADED_CONTRAHEN_UPDATE](state, data) {
        if (data) {
            state.contrahen = data;
        }
    },
    [ERRORS_CONTRAHEN_LOAD](state, data) {
        if (data.key) {
            // Если ошибка по key - редирект на страницу ошибки
            VueRouter.push("/404");
        }else{
            state.contrahen_errors = data;
        }
    },
    [BPM_SET_EMPTY_CONTRAHEN](state) {
        state.contrahen = contrahen_empty();
    },

    [LOADING](state, data) {
        state.loading        = data;
    },

    [BPM_STATS_LOADING](state, data) {
        state.stats_loading = data;
    },

    [ACTION_RESTORED](state, data) {
        if (data) {
            state.bp.actions.push(data);
            state.bp.trash.actions = state.bp.trash.actions.filter((action) => {
                return action.id !== data.id;
            });
        }
    },

    [ACTION_JUMP_RESTORED](state, data) {
        if (data) {
            const action = state.bp.actions.find((act) => {
                return act.id === data.bpm_action_id;
            });
            if (action) {
                if (!action.jumps) {
                    action.jumps = [];
                }
                action.jumps.push(data);
            }
            state.bp.trash.jumps = state.bp.trash.jumps.filter((jump) => {
                return jump.id !== data.id;
            });
        }
    },

    [BPM_STATS_LOADED](state, data) {
        if (data && !_.isEmpty(data.data)) {
            state.stats = data.data;
        } else {
            state.stats = {};
        }
    },

    [BPM_STUCK_LOADING] (state, data) {
        state.stuck_loading = data;
    },

    [BPM_STUCK_LOADED](state, data) {
        if (data && !_.isEmpty(data.data)) {
            state.stuck = data.data;
        } else {
            state.stuck = {};
        }
    },

    [BPM_ERROR_SMS_LOADING] (state, data) {
        state.error_sms_loading = data;
    },

    [BPM_ERROR_SMS_LOADED](state, data) {
        if (data && !_.isEmpty(data.data)) {
            state.error_sms = data.data;
        } else {
            state.error_sms = {};
        }
    },

    [BPM_SAVE_JUMP_ARROW_POS](state, data) {
        if (!state.jumpPosList[data.action_id]) {
            Vue.set(state.jumpPosList, data.action_id, {});
        }
        Vue.set(state.jumpPosList[data.action_id], data.id, data);
    },

    [BPM_STATS_SET_OPTIONS](state, data) {
        if (_.isArray(data)) {
            _.each(data, ({ field, value }) => {
                Vue.set(state.statsOptions, field, value);
            });
        }
    },
};

export default {
    state,
    actions,
    mutations
}
