import api from '../../../lib/api';

export const EMAIL_PROVIDER_LOAD = 'EMAIL_PROVIDER_LOAD';
export const EMAIL_PROVIDER_UPDATE = 'EMAIL_PROVIDER_UPDATE';
export const EMAIL_PROVIDER_CREATE = 'EMAIL_PROVIDER_CREATE';
export const EMAIL_PROVIDER_LOAD_INTEGRATIONS_LIST = 'EMAIL_PROVIDER_LOAD_INTEGRATIONS_LIST';

export const EMAIL_PROVIDER_LOAD_DATATABLE = 'EMAIL_PROVIDER_LOAD_DATATABLE';

export const EMAIL_PROVIDER_CHANGE = 'EMAIL_PROVIDER_CHANGE';
const EMAIL_PROVIDER_LOADING_CHANGE = 'EMAIL_PROVIDER_LOADING_CHANGE';
const EMAIL_PROVIDER_SAVING_CHANGE = 'EMAIL_PROVIDER_SAVING_CHANGE';
const EMAIL_PROVIDER_ERRORS_CHANGE = 'EMAIL_PROVIDER_ERRORS_CHANGE';

const EMAIL_PROVIDER_CREATING_CHANGE = 'EMAIL_PROVIDER_CREATING_CHANGE';

const EMAIL_PROVIDER_DATATABLE_CHANGE = 'EMAIL_PROVIDER_DATATABLE_CHANGE';
const EMAIL_PROVIDER_DATATABLE_LOADING_CHANGE = 'EMAIL_PROVIDER_DATATABLE_LOADING_CHANGE';

const EMAIL_PROVIDER_INTEGRATIONS_CHANGE = 'EMAIL_PROVIDER_INTEGRATIONS_CHANGE';
const EMAIL_PROVIDER_INTEGRATIONS_LOADING_CHANGE = 'EMAIL_PROVIDER_INTEGRATIONS_LOADING_CHANGE';

export const EMAIL_PROVIDER_DELETE = 'EMAIL_PROVIDER_DELETE';
export const EMAIL_PROVIDER_DELETE_CHANGE = 'EMAIL_PROVIDER_DELETE_CHANGE';

const state = {
    email_provider: null,
    email_provider_loading: false,
    email_provider_saving: false,
    email_provider_errors: {},

    email_provider_list: null,
    email_provider_list_loading: false,
    email_provider_creating: false,

    email_provider_creating_errors: {},
    email_provider_empty: {
        id: 0,
        name: null,
        email_integration: null,
        data: {},
    },

    email_integration_list: null,
    email_integration_list_loading: false,
};

const actions = {
    [EMAIL_PROVIDER_LOAD]({commit, state}, id) {
        if (!id) {
            commit(EMAIL_PROVIDER_CHANGE, _.cloneDeep(state.email_provider_empty));
            return true;
        }
        return api.load('email_provider',
            id,
            state,
            commit,
            EMAIL_PROVIDER_LOADING_CHANGE,
            EMAIL_PROVIDER_CHANGE);
    },

    [EMAIL_PROVIDER_UPDATE]({commit, state}, data) {
        let id = data.id;
        let model = data.model;

        return api.update('email_provider',
            id,
            model,
            state,
            commit,
            EMAIL_PROVIDER_SAVING_CHANGE,
            EMAIL_PROVIDER_CHANGE,
            EMAIL_PROVIDER_ERRORS_CHANGE);
    },

    [EMAIL_PROVIDER_CREATE]({commit, state}, data) {
        let model = data.model;
        let id = data.id ? data.id : 0;

        return api.create('email_provider.list',
            id,
            model,
            state,
            commit,
            EMAIL_PROVIDER_CREATING_CHANGE,
            EMAIL_PROVIDER_CHANGE,
            EMAIL_PROVIDER_ERRORS_CHANGE);
    },

    [EMAIL_PROVIDER_LOAD_DATATABLE]({commit, state}, id = 0) {
        id = !id ? id : 0;

        return api.list(
            'email_provider.list',
            id,
            null,
            state,
            commit,
            EMAIL_PROVIDER_DATATABLE_LOADING_CHANGE,
            EMAIL_PROVIDER_DATATABLE_CHANGE);
    },
    [EMAIL_PROVIDER_DELETE]({commit, state}, id) {
        id = !id ? id : 0;

        return api.delete(
            'email_provider',
            id,
            state,
            commit,
            EMAIL_PROVIDER_DELETE_CHANGE
        );
    },

    [EMAIL_PROVIDER_LOAD_INTEGRATIONS_LIST]({commit, state}) {
        return api.list(
            'email_provider.integrations',
            0,
            null,
            state,
            commit,
            EMAIL_PROVIDER_INTEGRATIONS_LOADING_CHANGE,
            EMAIL_PROVIDER_INTEGRATIONS_CHANGE
        );
    },
};

const mutations = {
    [EMAIL_PROVIDER_DATATABLE_LOADING_CHANGE](state, data) {
        state.email_provider_list_loading = data;
    },
    [EMAIL_PROVIDER_DATATABLE_CHANGE](state, data) {

        if (data) {
            state.email_provider_list = data.data;
        } else {
            state.email_provider_list = [];
        }
    },
    [EMAIL_PROVIDER_INTEGRATIONS_LOADING_CHANGE](state, data) {
        state.email_integration_list_loading = data;
    },
    [EMAIL_PROVIDER_INTEGRATIONS_CHANGE](state, data) {
        if (data) {
            state.email_integration_list = data.data;
        } else {
            state.email_integration_list = [];
        }
    },
    [EMAIL_PROVIDER_CHANGE](state, data) {
        state.email_provider = data;
    },
    [EMAIL_PROVIDER_LOADING_CHANGE](state, data) {
        state.email_provider_loading = data;
    },
    [EMAIL_PROVIDER_SAVING_CHANGE](state, data) {
        state.email_provider_saving = data;
    },
    [EMAIL_PROVIDER_ERRORS_CHANGE](state, data) {
        state.email_provider_errors = data;
    },
    [EMAIL_PROVIDER_CREATING_CHANGE](state, data) {
        state.email_provider_creating = data;
    },
    [EMAIL_PROVIDER_DELETE_CHANGE](state, id) {

        if (id) {
            if (state.email_provider_list) {

                let list = [];
                state.email_provider_list.forEach(email_provider => {
                    if (email_provider.id !== id) {
                        list.push({...email_provider});
                    }
                });

                state.email_provider_list = list;
            }
        }
    },
};

const getters = {
    isLoading: state => state.email_provider_loading,
    isSaving: state => state.email_provider_saving,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
